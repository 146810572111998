import React, { useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { toCssPrefix, breakpoints, Country, sendBannerEventToGTM, sendPromoClickToGTM } from '@ecommerce/shared'
import { ContentfulImage, MlBanner, GoogleTagManagerEventType } from '../../graphql/contentfulTypes'
import { ratio } from './bannerRatios'
import { BannerImageColumn } from './BannerImageColumn'
import { BannerTextColumn } from './BannerTextColumn'

const { cssPrefix, toPrefix } = toCssPrefix('Banner__')

interface BannerWrapperProps {
  layout: 'text-image' | 'image-text-image' | 'image-text'
  backgroundImages: {
    mobile: string
    tablet: string
    desktop: string
  }
  clickable?: boolean
}

const minTabletResolution = 678

const Wrapper = styled.div<BannerWrapperProps>`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  user-select: none;
  width: 100vw;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-image: url(${({ backgroundImages: { mobile } }) => mobile});
  background-size: cover;
  position: relative;
  .alert {
    position: absolute;
    bottom: 0;
    right: 0;
    background: black;
    color: white;
    height: 24px;
    width: 100%;
    display: flex;
    font-size: 10pt;
    justify-content: center;
    align-items: center;
  }
  .${cssPrefix} {
    &container {
      height: calc(100vw * ${ratio.mobile});
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 50%);
    }
    &desktop {
      display: none;
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    background-position: center;
    background-image: url(${({ backgroundImages: { tablet } }) => tablet});
    .${cssPrefix} {
      &container {
        height: calc(100vw * ${ratio.tablet});
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    background-image: url(${({ backgroundImages: { desktop } }) => desktop});
    .${cssPrefix} {
      &container {
        width: 1025px;
        height: 500px;
        ${({ layout }) => layout === 'image-text-image' && `grid-template-columns: repeat(3, minmax(0, 1fr))`};
      }
      &desktop {
        display: flex;
      }
      &dropdown-button {
        top: 442px;
      }
    }
  }
`

type BannerProps = MlBanner & { country: Country; index: number; city?: string }

export const Banner = (props: BannerProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const extractImageUrl = (contentfulImage: ContentfulImage) => `${contentfulImage.file.url}?q=80`
  const { layout } = props
  const backgroundImages = {
    mobile: extractImageUrl(props.backgroundImageMobile),
    tablet: extractImageUrl(props.backgroundImageTablet),
    desktop: extractImageUrl(props.backgroundImageDesktop),
  }
  const productImageMobile = extractImageUrl(props.productImageMobile)
  const productImageTablet = extractImageUrl(props.productImageTablet)
  const productImagesDesktop = props.productImagesDesktop.map(extractImageUrl)

  const [width, setWidth] = useState(0)

  const onAction = () => {
    const { name, index, city, googleTagManagerEventType, googleTagManagerEventValue } = props

    if (name && city && index !== undefined) {
      sendBannerEventToGTM({ campaign: name, city, position: index + 1 })
      if (googleTagManagerEventType === GoogleTagManagerEventType.PROMOTION && googleTagManagerEventValue) {
        sendPromoClickToGTM({
          id: googleTagManagerEventValue,
          name: googleTagManagerEventValue,
          creative: name,
          position: `${index + 1}`,
        })
      }
    }

    if (props.actionSlug.actionSlug) navigate(props.actionSlug.actionSlug)
  }
  const onBannerClick = () => {
    if (!props.buttonText) onAction()
  }

  const mainImageColumn = (
    <BannerImageColumn
      className={toPrefix('column')}
      images={{ mobile: productImageMobile, tablet: productImageTablet, desktop: productImagesDesktop[0] }}
    />
  )

  const textColumn = <BannerTextColumn className={toPrefix('column')} {...props} onAction={onAction} />

  const getWidth = () => {
    if (ref?.current?.offsetWidth) {
      setWidth(ref?.current?.offsetWidth)
    }
  }

  useEffect(() => {
    getWidth()
  }, [width])

  useEffect(() => {
    window.addEventListener('resize', getWidth)
  }, [])

  const generateBannerContent = () => {
    switch (layout) {
      case 'image-text-image':
        return (
          <>
            {mainImageColumn}
            {textColumn}
            <BannerImageColumn
              className={toPrefix('desktop')}
              images={{ mobile: productImageMobile, tablet: productImageTablet, desktop: productImagesDesktop[1] }}
            />
          </>
        )
      case 'text-image':
        return (
          <>
            {textColumn}
            {mainImageColumn}
          </>
        )
      default:
        return (
          <>
            {width <= minTabletResolution ? (
              <>
                {textColumn}
                {mainImageColumn}
              </>
            ) : (
              <>
                {mainImageColumn}
                {textColumn}
              </>
            )}
          </>
        )
    }
  }

  return (
    <Wrapper
      ref={ref}
      {...{
        layout,
        backgroundImages,
        dropdownButtonPosition: props.dropdownButtonPosition ?? 'right',
      }}
      clickable={!props.buttonText && props.actionSlug.actionSlug}
      onClick={onBannerClick}
    >
      <div className={toPrefix('container')}>{generateBannerContent()}</div>
      {props.alcoholicBeverageBlock && <div className="alert">{props.alcoholicBeverageBlock}</div>}
    </Wrapper>
  )
}
