import React from 'react'
import styled, { css } from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { toCssPrefix, Button, breakpoints, Country } from '@ecommerce/shared'
import { BannerText, RichTextJson } from '../../graphql/contentfulTypes'
import { BannerDiscountLabel } from '../BannerDiscountLabel'

const { cssPrefix, toPrefix } = toCssPrefix('BannerTextColumn__')

const noSpacing = css`
  margin: 0;
  padding: 0;
`

const alignText = ({ textAlignment }: { textAlignment?: BannerText['textAlignment'] }) => `
  text-align: ${textAlignment ?? 'left'};
`

const TextColumn = styled.div<{
  contentAlignment?: BannerText['contentAlignment']
  textAlignment?: BannerText['textAlignment']
  referentialPriceStrikethrough?: BannerText['referentialPriceStrikethrough']
  referentialPriceColor?: BannerText['referentialPriceColor']
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ contentAlignment }) => {
    if (contentAlignment === 'start') return 'flex-start'
    if (contentAlignment === 'end') return 'flex-end'
    return 'center'
  }};
  align-items: ${({ textAlignment }) => {
    if (textAlignment === 'left') return 'flex-start'
    if (textAlignment === 'right') return 'flex-end'
    return 'center'
  }};
  padding: 4vw;
  .${cssPrefix} {
    ${alignText};
    &referential-price {
      ${alignText};
      ${noSpacing};
      font-size: 10pt;
      font-weight: bold;
      text-decoration: ${({ referentialPriceStrikethrough }) => {
        if (referentialPriceStrikethrough) return 'line-through'
        return 'none'
      }};
      text-decoration-color: ${({ referentialPriceColor }) => {
        return `${referentialPriceColor}`
      }};
      p {
        margin: 0;
      }
    }
    &discount-label {
      margin-bottom: 16px;
    }
    &pretitle {
      margin-bottom: 4px;
      p {
        ${alignText};
        font-size: 10pt;
        font-weight: bold;
        ${noSpacing};
      }
    }
    &title {
      margin-bottom: 4px;
      p {
        ${alignText};
        line-height: 21pt;
        letter-spacing: -0.5px;
        font-size: 18pt;
        font-weight: bold;
        ${noSpacing};
      }
    }
    &promotional-code {
      margin-bottom: 12px;
      p {
        padding: 0 4px;
        ${alignText};
        font-size: 18pt;
        font-weight: bold;
        ${noSpacing};
      }
    }
    &subtitle {
      margin-bottom: 10px;
      p {
        ${alignText};
        font-size: 13pt;
        line-height: 18pt;
        ${noSpacing};
      }
    }
    &footer {
      display: flex;
      flex-direction: column;
      align-items: ${({ textAlignment }) => {
        if (textAlignment === 'left') return 'flex-start'
        if (textAlignment === 'right') return 'flex-end'
        return 'center'
      }};
      justify-content: space-between;
      width: 100%;
      &-button {
        margin-bottom: 8px;
        font-size: 13px;
        min-width: 96px;
        height: 30px;
        p {
          ${noSpacing};
        }
      }
      &-hashtag {
        p {
          ${alignText};
          font-size: 11pt;
          font-weight: bold;
          ${noSpacing};
          margin-bottom: 6px;
          margin-left: 2vw;
        }
      }
    }
    &hashtag {
      display: none;
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    align-items: ${({ textAlignment }) => {
      if (textAlignment === 'left') return 'flex-start'
      if (textAlignment === 'right') return 'flex-end'
      return 'center'
    }};
    padding: 30px;
    .${cssPrefix} {
      &discount-label {
        margin-bottom: 24px;
      }
      &pretitle {
        margin-bottom: 8px;
        p {
          font-size: 12px;
        }
      }
      &title {
        margin-bottom: 8px;
        p {
          font-size: 21px;
        }
      }
      &subtitle {
        margin-bottom: 23px;
        p {
          font-size: 14px;
        }
      }
      &footer {
        justify-content: ${({ textAlignment }) => {
          if (textAlignment === 'left') return 'flex-start'
          if (textAlignment === 'right') return 'flex-end'
          if (textAlignment === 'center') return 'center'
        }};
        &-button {
          margin-bottom: 8px;
          min-width: 96px;
          font-size: 17px;
          height: 40px;
          p {
            ${noSpacing};
          }
        }
        &-hashtag {
          display: none;
        }
      }
      &hashtag {
        display: block;
        p {
          display: block;
          ${alignText};
          font-size: 14px;
          font-weight: bold;
          ${noSpacing};
        }
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &pretitle {
        p {
          font-size: 16px;
        }
      }
      &title {
        p {
          font-size: 32px;
          line-height: 32px;
        }
      }
      &subtitle {
        p {
          font-size: 16px;
        }
      }
      &hashtag {
        p {
          font-size: 16px;
        }
      }
    }
  }
`

type TextColor = 'red' | 'black' | 'white' | 'green' | 'blue'

const BannerRichTextWrapper = styled.div<{ color?: TextColor; background?: TextColor }>`
  color: ${({ theme, color }) => {
    switch (color) {
      case 'red':
        return theme.colors.primary
      case 'black':
        return theme.colors.black
      case 'white':
        return theme.colors.white
      case 'blue':
        return theme.colors.blue
      case 'green':
        return theme.colors.green
      default:
        return 'auto'
    }
  }};

  background: ${({ theme, background }) => {
    switch (background) {
      case 'red':
        return theme.colors.primary
      case 'black':
        return theme.colors.black
      case 'white':
        return theme.colors.white
      case 'blue':
        return theme.colors.blue
      case 'green':
        return theme.colors.green
      default:
        return 'auto'
    }
  }};
`

type BannerTextColumn = BannerText & { className: string; onAction?: () => void; country: Country }

export const BannerTextColumn = ({
  className,
  referentialPrice,
  referentialPriceColor,
  referentialPriceStrikethrough,
  discount,
  discountType,
  discountLabelColor,
  discountLabelText,
  textAlignment,
  contentAlignment,
  buttonColor,
  buttonText,
  buttonType,
  preTitle,
  preTitleColor,
  title,
  titleColor,
  promotionalCode,
  promotionalCodeColor,
  promotionalCodeBackgroundColor,
  subtitle,
  subtitleColor,
  hashtag,
  hashtagColor,
  onAction,
  country,
}: BannerTextColumn) => {
  const renderText = ({
    background,
    color,
    richText,
  }: {
    background?: TextColor
    color?: TextColor
    richText: RichTextJson
  }) => {
    return (
      <BannerRichTextWrapper background={background} color={color}>
        {documentToReactComponents(richText.json)}
      </BannerRichTextWrapper>
    )
  }

  const getButtonType = () => {
    switch (buttonType) {
      case 'outline':
        return 'secondary'

      case 'text':
        return 'tertiary'

      default:
        return 'primary'
    }
  }

  const getButtonColor = () => {
    if (buttonColor === 'white-red') return 'white'
    if (buttonColor === 'white') return 'blackInverted'
    return buttonColor
  }

  return (
    <TextColumn
      className={className}
      contentAlignment={contentAlignment}
      textAlignment={textAlignment}
      referentialPriceStrikethrough={referentialPriceStrikethrough}
      referentialPriceColor={referentialPriceColor}
    >
      {referentialPrice && (
        <div className={toPrefix('referential-price')}>
          {renderText({ richText: referentialPrice, color: referentialPriceColor })}
        </div>
      )}
      {discount && (
        <BannerDiscountLabel
          currencySymbol={country === Country.BOLIVIA ? 'Bs' : '$'}
          discount={discount}
          discountType={discountType ?? 'percentage'}
          labelColor={discountLabelColor ?? 'black'}
          sideText={discountLabelText}
          className={toPrefix('discount-label')}
        />
      )}
      {preTitle && (
        <div className={toPrefix('pretitle')}>{renderText({ richText: preTitle, color: preTitleColor })}</div>
      )}
      {title && <div className={toPrefix('title')}>{renderText({ richText: title, color: titleColor })}</div>}
      {promotionalCode && (
        <div className={toPrefix('promotional-code')}>
          {renderText({
            richText: promotionalCode,
            color: promotionalCodeColor,
            background: promotionalCodeBackgroundColor,
          })}
        </div>
      )}
      {subtitle && (
        <div className={toPrefix('subtitle')}>{renderText({ richText: subtitle, color: subtitleColor })}</div>
      )}
      {buttonText && (
        <div className={toPrefix('footer')}>
          <Button
            className={toPrefix('footer-button')}
            btnType={getButtonType()}
            btnColor={getButtonColor()}
            onClick={() => (onAction ? onAction() : null)}
          >
            {documentToReactComponents(buttonText.json)}
          </Button>
          {hashtag && (
            <div className={toPrefix('footer-hashtag')}>{renderText({ richText: hashtag, color: hashtagColor })}</div>
          )}
        </div>
      )}
      {hashtag && <div className={toPrefix('hashtag')}>{renderText({ richText: hashtag, color: hashtagColor })}</div>}
    </TextColumn>
  )
}
