import { Anchor, breakpoints, toCssPrefix } from '@ecommerce/shared'
import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import SkeletonItem from '../Skeletons/SkeletonItem'
import { Icon } from '../Icon/Icon'
import { BlockPadding } from '../../graphql/contentfulTypes'

const { cssPrefix, toPrefix: prefix } = toCssPrefix('ProductListHomeWrapper__')

const getPadding = ({ paddingTop, paddingBottom }: BlockPadding) => css`
  padding-top: ${paddingTop ? '40px' : '20px'};
  padding-bottom: ${paddingBottom ? '40px' : '20px'};
`

export type StyleProps = {
  bg?: string
  color?: string
  bgTop?: string
  bgBottom?: string
} & BlockPadding

const Wrapper = styled.div<StyleProps>`
  color: ${({ theme }) => theme.colors.bodyText};
  ${getPadding}
  position: relative;
  .${cssPrefix} {
    &header {
      padding-left: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 17px;
      &-action {
        color: ${({ theme }) => theme.colors.bodyText};
        display: flex;
        align-items: flex-start;
        margin-top: 5px;
        &-icon {
          margin-top: -5px;
        }
      }
      &-title {
        margin: 0;
      }
    }
  }
  .bg {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    width: 100%;
    height: 84%;
    max-height: 384px;
    margin-top: ${({ paddingTop, paddingBottom }) => {
      if (paddingTop && !paddingBottom) return '-16px'
      if (!paddingTop && paddingBottom) return '4px'
      if (paddingTop && paddingBottom) return '-12px'
    }};

    &-first-section {
      background: ${({ theme, bgTop }) => bgTop ?? theme.colors.bodyBackground.default};
      height: 35%;
    }

    &-second-section {
      background: ${({ theme, bgBottom }) => bgBottom ?? theme.colors.bodyBackground.alt};
      background-position-x: center;
      background-size: cover;
      background-position-y: bottom;
      height: 65%;
    }
  }
  @media (${breakpoints.desktop.min}) {
    ${getPadding}
    padding-left: 105px;
    padding-right: 105px;
    .${cssPrefix} {
      &header {
        padding-left: 0;
      }
    }
    .bg {
      height: 87%;
      margin-top: ${({ paddingTop, paddingBottom }) => {
        if (paddingTop && !paddingBottom) return '-17px'
        if (!paddingTop && paddingBottom) return '3px'
        if (paddingTop && paddingBottom) return '-15px'
      }};
      &-first-section {
        height: 35%;
      }
      &-second-section {
        height: 65%;
      }
    }
  }
`

export interface Props extends HTMLAttributes<HTMLDivElement>, StyleProps {
  title: string
  onShowMoreClick?: () => void
  showMoreText?: string
  hideNavigation?: boolean
  isLoading?: boolean
}

export default (props: Props) => {
  const {
    children,
    title,
    onShowMoreClick,
    showMoreText = 'Ver todo',
    hideNavigation = false,
    isLoading = false,
    ...restProps
  } = props
  return (
    <Wrapper {...restProps}>
      <div className={prefix('header')}>
        {isLoading ? (
          <SkeletonItem height={50} width="50%" margin="0 0 25px 0" animate />
        ) : (
          <h2 className={prefix('header-title')}>{title}</h2>
        )}
        {!hideNavigation && (
          <Anchor className={prefix('header-action')} onClick={() => (onShowMoreClick ? onShowMoreClick() : null)}>
            {showMoreText}
            <Icon className={prefix('header-action-icon')} iconId="chevron_right" size="26" />
          </Anchor>
        )}
      </div>
      <div className="bg">
        <div className="bg-first-section" />
        <div className="bg-second-section" />
      </div>
      {children}
    </Wrapper>
  )
}
